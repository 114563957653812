import React from 'react';
import styled from 'styled-components';

import PageStripedHeader from '../PageStripedHeader';
import Breadcrumb from '../Breadcrumb';

const EventHeader = ({ eventDate, title, location, pathname, parentName }) => {
  const displayDate = eventDate.slice(0, eventDate.lastIndexOf(' ') - 1);
  const displayTime = eventDate.slice(eventDate.lastIndexOf(' ') + 1);

  return (
    <PageStripedHeader>
      <section>
        <Breadcrumb pathname={pathname} parentName={parentName} />
        <StyledEventMeta>
          <time>
            <strong>{displayDate}</strong> {displayTime}
          </time>
        </StyledEventMeta>
        <StyledEventTitle>{title}</StyledEventTitle>
        <StyledEventSource>{location}</StyledEventSource>
      </section>
    </PageStripedHeader>
  );
};

const StyledEventMeta = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  display: block;
  padding-top: 1rem;
  margin-bottom: 0;

  & strong {
    font-weight: 500;
  }
`;

const StyledEventTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
`;

const StyledEventSource = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: 0.8125rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

export default EventHeader;
