import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import { Map, Marker, Overlay } from 'pigeon-maps';
import axios from 'axios';

import parse from 'html-react-parser';
import styled from 'styled-components';

import { isEmail } from '../../lib/helpers';
import PortableText from '../portableText';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const EventMain = ({
  description,
  details,
  flyer,
  ticketUrl,
  titleEmail,
  dateEmail,
  coords,
  location,
}) => {
  // console.log(coords);
  const defaultLatLng = [48.11407211244948, 11.738018055829166];
  const exactLocation = {
    lat: coords.lat,
    lng: coords.lng,
  };
  const [address, setAddress] = useState('');

  const bbox = `${exactLocation.lng - 0.002}%2C${exactLocation.lat - 0.002}%2C${
    exactLocation.lng + 0.002
  }%2C${exactLocation.lat + 0.002}`;

  useEffect(() => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${exactLocation.lat}&lon=${exactLocation.lng}`;

    axios(url).then((res) => {
      const addressHtml = ` <address>
      ${res.data.address.road || res.data.address.hamlet} ${
        res.data.address.house_number ? res.data.address.house_number : ''
      }<br>
      ${res.data.address.postcode} ${res.data.address.town || res.data.address.village}<br>
      ${res.data.address.state}
      </address> `;

      setAddress(addressHtml);
    });
  }, []);

  // console.log(exactLocation.lat, exactLocation.lng);
  // console.log(exactLocation.lat + 0.2, exactLocation.lng + 0.2);
  return (
    <StyledEventMain>
      {flyer ? (
        <Row>
          <Col md={6} className="pr-5">
            {details && details.length > 0 ? <PortableText blocks={details} /> : ''}
            <PortableText blocks={description} />
          </Col>
          <Col md={6}>
            <Img fluid={flyer} />
          </Col>
        </Row>
      ) : (
        <div
          style={{
            maxWidth: '35em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {details && details.length > 0 ? <PortableText blocks={details} /> : ''}
          <PortableText blocks={description} />
        </div>
      )}
      <Row className="pt-5">
        <Col
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <strong>Veranstaltungsort:</strong>
          <p>{location}</p>
          <div>{parse(address)}</div>
        </Col>
        <Col md={8}>
          <div>
            <iframe
              // width={425}
              id="eventMap"
              height={350}
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              src={`https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&layer=mapnik&marker=${exactLocation.lat}%2C${exactLocation.lng}`}
              style={{ border: 'none', width: '100%' }}
            />
            <br />
            <small>
              <a
                href={`https://www.openstreetmap.org/#map=19/${exactLocation.lat}/${exactLocation.lng}`}
                target="_blank"
              >
                Größere Karte anzeigen
              </a>
            </small>
          </div>
        </Col>
      </Row>

      <PageFooter>
        {ticketUrl && ticketUrl.linkUrl ? (
          isEmail(ticketUrl.linkUrl) ? (
            <OutlineBtnLg
              as="a"
              href={`mailto:${ticketUrl.linkUrl}?subject=Kartenreservierung%20für%20${titleEmail}%20am%20${dateEmail}`}
              btnText="Karten reservieren"
            />
          ) : (
            <OutlineBtnLg as="a" href={ticketUrl.linkUrl} btnText="Karten reservieren" />
          )
        ) : (
          ''
        )}
      </PageFooter>
    </StyledEventMain>
  );
};

const StyledEventMain = styled(Container)`
  margin-top: 4.875rem;
  margin-bottom: 0;
`;

export default EventMain;
