import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';

import localize from '../components/localize';
import { localizeDate } from '../lib/helpers';
import Layout from '../components/layout';
import SEO from '../components/seo';
import EventHeader from '../components/EventTemplate/EventHeader';
import EventMain from '../components/EventTemplate/EventMain';
import PageFooter from '../components/PageFooter';
import ShareButtons from '../components/ShareButtons';

const Event = ({ data, pageContext, path, location }) => {
  // console.log(location);
  const {
    title,
    eventDate,
    location: eventLocation,
    address,
    _rawShortDescription: info,
    _rawDetailedDdescription: details,
    flyer,
    ticketUrl,
  } = data.event;

  const localeEventDate = localizeDate(eventDate, pageContext.locale, 'long', true);
  const seoDescription = info[0].children[0].text;
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO
        title={title}
        description={seoDescription}
        image={flyer ? flyer.asset.fluid : null}
        lang={pageContext.locale}
        path={path}
        pageType="article"
      />
      <article>
        <EventHeader
          eventDate={localeEventDate}
          title={title}
          location={eventLocation}
          pathname={location.pathname}
          parentName={data.eventPage.title}
        />
        <EventMain
          description={info}
          details={details}
          location={eventLocation}
          coords={address}
          flyer={flyer ? flyer.asset.fluid : null}
          ticketUrl={ticketUrl ? ticketUrl : null}
          titleEmail={title}
          dateEmail={localeEventDate}
        />
        <PageFooter>
          <Container className="py-4 d-flex justify-content-center flex-wrap">
            <ShareButtons url={location.href} title={title} lang={pageContext.locale} />
          </Container>
        </PageFooter>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query EventTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    eventPage: sanityPage(id: { eq: "-63c5a03c-21f2-512e-81d7-e2961728aad4" }) {
      title {
        _type
        de
        en
      }
    }
    event: sanityEvent(id: { eq: $id }) {
      id
      title {
        _type
        de
        en
      }
      eventDate
      location
      address {
        lat
        lng
      }
      _rawShortDescription(resolveReferences: { maxDepth: 5 })
      _rawDetailedDdescription(resolveReferences: { maxDepth: 5 })
      flyer {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      ticketUrl {
        linkUrl
      }
    }
  }
`;

export default localize(Event);
